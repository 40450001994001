var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sell" },
    [
      _c(
        "van-pull-refresh",
        {
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing"
          }
        },
        [
          _c("div", { staticClass: "box" }, [
            _c("div", { staticClass: "flex_between" }, [
              _c("h3", { staticClass: "explain" }, [_vm._v("总押金 (元)")]),
              _c("h3", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm.totalDeposit))
              ])
            ]),
            _c("div", { staticClass: "flex_between  border" }, [
              _c("div", { staticClass: "explain" }, [
                _vm._v(
                  _vm._s(
                    _vm.goodsType === "baiyin"
                      ? "白银"
                      : _vm.goodsType === "bojin"
                      ? "铂金"
                      : _vm.goodsType === "bajin"
                      ? "钯金"
                      : "黄金"
                  ) + "现价(元/克)"
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "number",
                  style: {
                    color:
                      _vm.goodsType === "baiyin"
                        ? _vm.agRedColor
                        : _vm.goodsType === "bojin"
                        ? _vm.ptRedColor
                        : _vm.goodsType === "bajin"
                        ? _vm.pdRedColor
                        : _vm.redColor
                        ? "#ff2d2e"
                        : "#02790D"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.goodsType === "baiyin"
                          ? _vm.pricebaiy
                          : _vm.goodsType === "bojin"
                          ? _vm.priceboj
                          : _vm.goodsType === "bajin"
                          ? _vm.pricebaj
                          : _vm.pricehj
                      )
                  )
                ]
              )
            ]),
            _c("div", { staticClass: "flex_between marginTop" }, [
              _c("div", { staticClass: "explain " }, [
                _vm._v("订单数量（单）/订单总重量（克）")
              ]),
              _c("div", { staticClass: "number" }, [
                _vm._v(
                  _vm._s(_vm.checkList.length) + "/" + _vm._s(_vm.orderWeight)
                )
              ])
            ]),
            _c("div", { staticClass: "flex_between" }, [
              _c("div", { staticClass: "explain" }, [
                _vm._v("订单均价（元/克）")
              ]),
              _c("div", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm.averagePrice))
              ])
            ]),
            _c("div", { staticClass: "flex_between" }, [
              _c("div", { staticClass: "explain" }, [
                _vm._v("物料总差价（元）")
              ]),
              _c("div", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm.PriceDifference.toFixed(2)))
              ])
            ]),
            _c("div", { staticClass: "flex_between" }, [
              _c("div", { staticClass: "explain fontSize" }, [
                _vm._v("总保管费（元） ")
              ]),
              _c("div", { staticClass: "number fontSize" }, [
                _vm._v(_vm._s(_vm.CustodyFee))
              ])
            ]),
            _c("div", { staticClass: "flex_between" }, [
              _c(
                "div",
                { staticClass: "explain  fontSize" },
                [
                  _vm._v("占用总押金(元) "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "结算后将释放回可用押金",
                        placement: "top-start"
                      }
                    },
                    [
                      _c("van-icon", {
                        attrs: { name: "question-o", color: "#FF2D2E" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "number fontSize" }, [
                _vm._v(_vm._s(_vm.occupyDeposi))
              ])
            ]),
            _c("div", { staticClass: "flex_between  " }, [
              _c(
                "div",
                { staticClass: "explain JSFontSize fontWeight" },
                [
                  _vm._v("结算金额（元） "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "将累加到押金余额里",
                        placement: "top-start"
                      }
                    },
                    [
                      _c("van-icon", {
                        attrs: { name: "question-o", color: "#FF2D2E" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "number fontSize" }, [
                _vm._v(_vm._s(_vm.settlementTotalDeposit.toFixed(2)))
              ])
            ])
          ]),
          _c(
            "van-list",
            {
              attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
              on: {
                load: function($event) {
                  return _vm.onLoad(_vm.goodsType)
                }
              },
              model: {
                value: _vm.loading,
                callback: function($$v) {
                  _vm.loading = $$v
                },
                expression: "loading"
              }
            },
            _vm._l(_vm.getViolateList, function(item, index) {
              return _c("div", { key: index, staticClass: "flex" }, [
                _c("div", { staticClass: "line" }),
                _c("div", { staticClass: "orderInfo" }, [
                  _c("div", { staticClass: "flex_between borderBtm" }, [
                    _c("div", { staticClass: "flex" }, [
                      _c("h2", [_vm._v(_vm._s(item.goodName))]),
                      _c("div", { staticClass: "icon" }, [_vm._v(" 买料")])
                    ]),
                    _c("div", { staticClass: "flex" }, [
                      _c("div", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm._f("formatDate_")(item.created)))
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.checkList,
                            expression: "checkList"
                          }
                        ],
                        staticClass: "checkBox",
                        attrs: { type: "checkbox", name: "checkBoxTest" },
                        domProps: {
                          value: item,
                          checked: Array.isArray(_vm.checkList)
                            ? _vm._i(_vm.checkList, item) > -1
                            : _vm.checkList
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.checkList,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = item,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.checkList = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.checkList = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.checkList = $$c
                              }
                            },
                            _vm.checkBox
                          ]
                        }
                      })
                    ])
                  ]),
                  _c("div", { staticClass: "flex_between marginTop" }, [
                    _c("div", { staticClass: "explain" }, [
                      _vm._v("定价重量(克)/单价(元/克)")
                    ]),
                    _c("div", { staticClass: "number" }, [
                      _vm._v(_vm._s(item.weight) + "/" + _vm._s(item.price))
                    ])
                  ]),
                  _c("div", { staticClass: "flex_between marginTop" }, [
                    _c("div", { staticClass: "explain" }, [
                      _vm._v("押金（元）")
                    ]),
                    _c("div", { staticClass: "number" }, [
                      _vm._v(_vm._s(item.earnestMoney))
                    ])
                  ]),
                  _c("div", { staticClass: "flex_between marginTop" }, [
                    _c("div", { staticClass: "explain" }, [
                      _vm._v("保管费（元）")
                    ]),
                    _c("div", { staticClass: "number" }, [
                      _vm._v(
                        _vm._s(item.storageFee == 0 ? 0 : -item.storageFee)
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "flex_between marginTop" }, [
                    _c("div", { staticClass: "explain" }, [
                      _vm._v("物料差价（元）")
                    ]),
                    _c("div", { staticClass: "number" }, [
                      _vm._v(_vm._s(item.difference))
                    ])
                  ]),
                  _c("div", { staticClass: "flex_between marginTop" }, [
                    _c("div", { staticClass: "explain" }, [_vm._v("押金调整")]),
                    _c(
                      "div",
                      {
                        staticClass: "number",
                        on: {
                          click: function($event) {
                            return _vm.openShowPop(item.orderId, item)
                          }
                        }
                      },
                      [_c("van-icon", { attrs: { name: "arrow" } })],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "flex_between marginTop" }, [
                    _c("div", { staticClass: "explain" }, [
                      _vm._v("押金比例(%)")
                    ]),
                    _c("div", { staticClass: "number" }, [
                      _vm._v(_vm._s(item.depositProportion))
                    ])
                  ])
                ])
              ])
            }),
            0
          )
        ],
        1
      ),
      _c("showPop", {
        ref: "pop",
        attrs: {
          showPop: _vm.showPop,
          ViolateList: _vm.getViolateList,
          miniOrderPolicy: _vm.miniOrderPolicy,
          policy: _vm.policy
        },
        on: {
          "update:showPop": function($event) {
            _vm.showPop = $event
          },
          "update:show-pop": function($event) {
            _vm.showPop = $event
          }
        }
      }),
      _c(
        "van-dialog",
        {
          attrs: { "show-confirm-button": false, "get-container": "#app" },
          model: {
            value: _vm.showbreak,
            callback: function($$v) {
              _vm.showbreak = $$v
            },
            expression: "showbreak"
          }
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("结算")]),
          _c("div", { staticClass: "centerbox_dialog" }, [
            _c("div", { staticClass: "flex_between col999" }, [
              _c("div", [_vm._v("物料差价 (元)")]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.PriceDifference.toFixed(2)))])
            ]),
            _c("div", { staticClass: "flex_between col999" }, [
              _c("div", [_vm._v("保管费（元）")]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.CustodyFee))])
            ]),
            _c("div", { staticClass: "flex_between break" }, [
              _c("div", [_vm._v("结算金额（元）")]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.settlementTotalDeposit.toFixed(2)))])
            ])
          ]),
          _c("div", { staticClass: "flex_between popbtn" }, [
            _c(
              "div",
              { staticClass: "gocer closebtn", on: { click: _vm.close } },
              [_vm._v("取消")]
            ),
            _c(
              "div",
              { staticClass: "gocer confirmbtn", on: { click: _vm.commit } },
              [_vm._v("确定")]
            )
          ])
        ]
      ),
      _c(
        "van-dialog",
        {
          staticClass: "ceringModel",
          attrs: { "show-confirm-button": false, "get-container": "#app" },
          model: {
            value: _vm.ceringModel,
            callback: function($$v) {
              _vm.ceringModel = $$v
            },
            expression: "ceringModel"
          }
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("提示")]),
          _c("div", { staticClass: "centerbox_dialog" }, [
            _vm._v(_vm._s(_vm.stopTrading))
          ]),
          _c(
            "div",
            {
              staticClass: "confirm",
              on: {
                click: function($event) {
                  _vm.ceringModel = false
                }
              }
            },
            [_vm._v("我知道了")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }